import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Buttons, HeaderButton, XGridDemo, TransitionModal, Filters } from "components";

import { CONActivar, LupaIcon, ReloadIcon } from "assets/icons";
import borrar from "assets/icons/eliminar.svg";

import { usuariosNoUsuarios as titles } from "consts/tableTitles";
import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { ConnectionModal, DeleteModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
    modalData,
    routeInfo,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import styles from "styles/pages/Tables.module.scss";
import UsuariosNoUsuariosFilters from "./UsuariosNoUsuariosFilters";

export default function UsuariosNoUsuarios() {
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [usersNoUsers, setUsersNoUsers] = useState([]);

    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const [row, setRow] = useRecoilState(rowSelected);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setRoute = useSetRecoilState(routeInfo);

    async function handleUpdateTable(params = {}) {
        setIsLoading(true);

        const body = {
            service: "UsuarioNoUsuarioBasicosGet",
            params: {
                usuarioNoUsuarioId: params.usuarioNoUsuarioId,
                tipoDeDocumentoId: params.tipoDeDocumentoId,
                usuarioNroDeDocumentoX: params.usuarioNroDeDocumentoX ?? filters.usuarioNroDeDocumentoX,
                usuarioSexo: params.usuarioSexo,
                usuarioFechaDeNacimiento: params.usuarioSexo,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        const userNoUserPartial = result.map((item) => {
            const {
                usuarioNoUsuarioId,
                tipoDeDocumentoId,
                tipoDeDocumentoCod,
                tipoDeDocumentoNombre,
                usuarioNoUsuarioNroDeDocumentoX,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
                usuarioNoUsuarioDeviceId,
                usuarioNoUsuarioGCMToken,
                usuarioNoUsuarioEquipoMarca,
                usuarioNoUsuarioEquipoModelo,
                aplicacionId,
                aplicacionCod,
                aplicacionNombre,
                usuarioNoUsuarioAppVersion,
                usuarioNoUsuarioTelefonoCelular,
                usuarioNoUsuarioMail,
                usuarioNoUsuarioLatitud,
                usuarioNoUsuarioLongitud,
                usuarioNoUsuarioFechaAlta,
                usuarioNoUsuarioUID,
                usuarioNoUsuarioUFH,
                usuarioNoUsuarioVersion,
            } = item;

            return {
                id: usuarioNoUsuarioId,
                tipoDeDocumentoId,
                tipoDeDocumentoCod,
                tipoDeDocumentoNombre,
                usuarioNoUsuarioNroDeDocumentoX,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
                usuarioNoUsuarioDeviceId,
                usuarioNoUsuarioGCMToken,
                usuarioNoUsuarioEquipoMarca,
                usuarioNoUsuarioEquipoModelo,
                aplicacionId,
                aplicacionCod,
                aplicacionNombre,
                usuarioNoUsuarioAppVersion,
                usuarioNoUsuarioTelefonoCelular,
                usuarioNoUsuarioMail,
                usuarioNoUsuarioLatitud,
                usuarioNoUsuarioLongitud,
                usuarioNoUsuarioFechaAlta,
                usuarioNoUsuarioUID,
                usuarioNoUsuarioUFH,
                usuarioNoUsuarioVersion,
                [titles[0]]: usuarioNoUsuarioNroDeDocumentoX,
                [titles[1]]: usuarioNoUsuarioSexo,
                [titles[2]]: usuarioNoUsuarioFechaDeNacimiento
                    ? usuarioNoUsuarioFechaDeNacimiento.slice(0, 10)
                    : "",
                [titles[3]]: usuarioNoUsuarioTelefonoCelular,
                [titles[4]]: usuarioNoUsuarioMail,
                [titles[5]]: aplicacionCod,
                [titles[6]]: usuarioNoUsuarioFechaAlta?.slice(0, 10),
            };
        });

        setUsersNoUsers(userNoUserPartial);
        setRow({ status: false, row: {} });
        setIsLoading(false);
    }

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
        setRow({ status: false, row: {} });
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal>
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.DETAIL && (
                    <ConnectionModal
                        tipoDeDocumentoId={row.row.tipoDeDocumentoId}
                        usuarioNroDeDocumentoX={row.row.usuarioNoUsuarioNroDeDocumentoX}
                        usuarioSexo={row.row.usuarioNoUsuarioSexo}
                    />
                )}
            </TransitionModal>
            <Buttons filtersApplied>
                <Filters>
                    <UsuariosNoUsuariosFilters handleSubmit={handleUpdateTable} setFilters={setFilters} filters={filters} />
                </Filters>
                <span>
                    {row.status ? (
                        <>
                            <Link
                                to={`/no_usuarios/detail?no_user_id=${row.row.id}`}
                            >
                                <HeaderButton
                                    text={"Ver Detalle"}
                                    icon={LupaIcon}
                                    onClick={() =>
                                        setRoute({
                                            route: ROUTES["no_usuarios_detail"],
                                        })
                                    }
                                />
                            </Link>
                            <HeaderButton
                                text={"Ver conexiones"}
                                x
                                icon={CONActivar}
                                data={{
                                    action: ACTIONS.DETAIL,
                                    title: "Conexiones",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <HeaderButton
                            text={"Actualizar página"}
                            icon={ReloadIcon}
                            updateList={handleUpdateTable}
                        />
                    )}
                </span>
            </Buttons>

            <XGridDemo
                rows={usersNoUsers}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
