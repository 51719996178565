import React, { useEffect, useState } from "react";
import { ListDrawer } from "components/index";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import { GridMenuIcon } from "@material-ui/x-grid";

import LogoutIcon from "assets/icons/icon-logout.svg";

import { useRecoilValue } from "recoil";
import { userInfo, routeInfo } from "recoil/GlobalState";

import { logout } from "utils/Local-services";

import { ROUTES } from "consts/routes";

import { HeaderStyles } from "styles/components/Header/Header.styles";

export default function Header() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [pathname, setPathname] = useState("");

    const route = useRecoilValue(routeInfo);
    const { imagePathPERFIL, usuarioNombres, usuarioApellidos } =
        useRecoilValue(userInfo);

    const handleDrawerOpen = async () => {
        setOpenDrawer(!openDrawer);
    };

    useEffect(() => {
        setPathname(
            route?.wordKey
                ? route?.wordKey
                : ROUTES[location.pathname.split("/")[1]]?.wordKey
        );
    }, [route]);

    const styles = HeaderStyles();

    return (
        <>
            <AppBar position="relative" className={styles.root}>
                <Toolbar variant="dense" className={styles.toolbar}>
                    <IconButton
                        edge="start"
                        aria-label="menu"
                        color="inherit"
                        onClick={handleDrawerOpen}
                    >
                        <GridMenuIcon className={styles.menuIcon} />
                    </IconButton>

                    <Typography
                        className={styles.wordKey}
                        color="inherit"
                        variant="h4"
                    >
                        {pathname ? pathname : ""}
                    </Typography>

                    <Breadcrumb />
                </Toolbar>
            </AppBar>

            <Drawer
                className={styles.drawer}
                variant="persistent"
                anchor="left"
                open={openDrawer}
            >
                <div className={styles.userDescription}>
                    {imagePathPERFIL ? (
                        <img
                            className={styles.avatar}
                            alt="avatar"
                            src={imagePathPERFIL}
                        />
                    ) : (
                        <span className={styles.defaultAvatar}>{`${usuarioNombres.split("")[0]
                            }${usuarioApellidos?.split("")[0]}`}</span>
                    )}
                    <div className={styles.usernameContainer}>
                        <p>
                            {`${usuarioNombres.split(" ")[0]
                                } ${usuarioApellidos}`}
                        </p>
                        <div className={styles.logout} onClick={logout}>
                            <img src={LogoutIcon} alt="logout" />
                            <p>Cerrar sesión</p>
                        </div>
                    </div>
                </div>
                <div className={styles.listContainer}>
                    <ListDrawer setOpen={setOpenDrawer} />
                </div>
                <div className={styles.drawerFooter}>
                    ® Mia | Todos los derechos reservados | 1.02
                </div>
            </Drawer>
            {openDrawer ? (
                <div
                    className={styles.drawerHandler}
                    onClick={() => setOpenDrawer(false)}
                ></div>
            ) : null}
        </>
    );
}
